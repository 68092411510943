import React, { useState, useEffect, useRef } from 'react';
import IframeResizer from 'iframe-resizer-react';
import TestimonialCard from '../TestimonialCard';
import { sendUTMsToIframe } from '../../../util/send-utms-to-iframe';
import { useAppContext } from '../../../util/context';
import useBetterMediaQuery from '../../../util/better-media-query';

// custom hook for calculating distance to bottom
function useDistanceToBottom(ref) {
  const [distance, setDistance] = useState(0);

  useEffect(() => {
    const calculateDistance = () => {
      const rect = ref.current.getBoundingClientRect();
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      return rect.top + scrollTop + rect.height;
    };

    const updateDistance = () => {
      setDistance(calculateDistance());
    };

    updateDistance();

    window.addEventListener('resize', updateDistance);

    return () => {
      window.removeEventListener('resize', updateDistance);
    };
  }, [ref]);

  return distance;
}


export const ContactFormHeroSingleColumn = ({
  className = '',
  anchor = '',
  top_heading,
  page_heading,
  description,
  form_url,
  bg,
  testimonials_carousel,
  testimonials = [],
  bid,
  accent,
  layout,
}) => {

  const isMobile = useBetterMediaQuery('(max-width: 1023px)');
  const elementRef = useRef(null);
  const distanceToBottom = useDistanceToBottom(elementRef);
  const context = useAppContext();

  useEffect(() => {
    // context.toggleDarkMode(false);
  }, [context]);

  const layoutStyleDefs = {
    '1': { //"Contact"
      headerBgStyles: {
        backgroundSize: `100% ${distanceToBottom}px`,
        backgroundPosition: 'top',
        backgroundImage: `url(${bg?.src})`
      },
      h1Class: 'md:h2',
      contentClass: 'pb-24 lg:pb-16',
      formClass: '-mt-24 lg:mt-0',
      cardClass: '',
    },
    '2': { //"Demo"
      headerBgStyles: {
        backgroundSize: `${isMobile ? 'contain' : '100% 360px'}`,
        backgroundPosition: 'bottom',
        backgroundImage: `url(${bg?.src})`
      },
      h1Class: 'md:h3',
      contentClass: 'xl:px-8',
      formClass: '',
      cardClass: 'bg-opacity-60',
    },
    'default': {
      headerBgStyles: {
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      },
      h1Class: 'md:h2',
      contentClass: '',
      formClass: '',
      cardClass: '',
    },
  };

  const { headerBgStyles, h1Class, contentClass, formClass, cardClass } = layoutStyleDefs[layout] || layoutStyleDefs['default'];

  let first_testimonial = null;

  if (testimonials_carousel?.length > 0) {
    const first_testimonial_id = testimonials_carousel[0]; // get the first item
    first_testimonial = testimonials.find(item => String(item.id) === first_testimonial_id);
  }

  sendUTMsToIframe();


  return (

    <header
      className={`wow fadeInUp lg:grid lg:pb-20 pb-12 pt-24 md:pt-36 xl:pt-48 bg-no-repeat contact-form-hero contact-form-hero--layout-${layout} ${className}}`}
      id={anchor}
      data-name="contact-form-hero"
      data-accent={`${accent}`}
      style={{ ...headerBgStyles }}
    >
      <div className="container">
        <div className="grid gap-8 lg:grid-cols-1">

          <div className={`${contentClass} d-none`} ref={elementRef}>
            {top_heading &&
              <p className={`mb-4 font-semibold md:mb-6 contact-form-hero__eyebrow label wow fadeInUp flex items-center`}>
                <svg width='12' height='12'>
                  <circle cx='6' cy='6' r='5' stroke='currentColor' fill='white' strokeWidth='2' />
                </svg>
                <span className={`ml-2`} dangerouslySetInnerHTML={{ __html: top_heading, }} />
              </p>}
            {page_heading &&
              <h1 className={`${h1Class} mb-4 lg:mb-8 wow fadeInUp`} dangerouslySetInnerHTML={{ __html: page_heading, }} />}
            {description &&
              <div
                className={`lead lg:lead--large wow fadeInUp contact-form-hero__description font-light`}
                dangerouslySetInnerHTML={{ __html: description, }}
              />}
          </div>

          {form_url &&
            <div className={`flex flex-col gap-y-6 CUSTOM-FORM ${formClass}`}>
              <div className={`rounded-10 w-full  bg-white relative ${cardClass}`}>
                <IframeResizer
                  src={form_url}
                  id="pardot-iframe"
                  width="100%"
                  allowtransparency="true"
                  type="text/html"
                  sandbox="allow-downloads allow-same-origin allow-scripts allow-popups allow-forms allow-top-navigation-by-user-activation allow-same-origin"
                />
              </div>
            </div>}
          <div>

            {!isMobile && first_testimonial && layout === '1' &&
              <TestimonialCard testimonial={first_testimonial} className="lg:mt-16" />
            }
          </div>
          {isMobile && first_testimonial &&
            <TestimonialCard testimonial={first_testimonial} className="lg:mt-16" />
          }
        </div>

        {(!isMobile && first_testimonial && layout === '2') &&
          <TestimonialCard testimonial={first_testimonial} className="lg:mt-16 mx-auto lg:w-2/3" />
        }
      </div>
    </header>
  );
};
export default ContactFormHeroSingleColumn;
