import React from 'react';
import { extractRepeaterField } from '../../util/blocks';

import { FeaturesGrid } from '../../components/enfusion/FeaturesGrid';

function ACFFeaturesGrid({ meta, data = [], className, anchor, pageProps }) {
    const { themeAssets } = pageProps;

    const { main_heading, top_heading, text_area } = data;

    let grid = extractRepeaterField(data, 'grid');

    grid = grid.map((repeaterItem) => ({
        ...repeaterItem,
        desktop_image: meta?.images[repeaterItem.desktop_image] || {},
        mobile_image: meta?.images[repeaterItem.mobile_image] || {},
        icon: themeAssets[repeaterItem.icon] || {},
        style_options_background_pattern:
            themeAssets[repeaterItem.style_options_background_pattern] || {}
    }));
    
    return (
        <FeaturesGrid
            className={className}
            anchor={anchor}
            main_heading={main_heading}
            top_heading={top_heading}
            text_area={text_area}
            grid={grid}
        />
    );
}

export default ACFFeaturesGrid;
